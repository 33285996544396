.image2Icon {
  position: relative;
  width: 396px;
  height: 198px;
  object-fit: cover;
}
.invoiceNumberGenerator {
  position: relative;
  font-weight: 300;
}
.invoiceNumberGeneratorV101Wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  align-items: center;
  justify-content: center;
}
.inputoutlined {
  border: 0;
  background-color: transparent;
}
.buttontextAndIcon {
  cursor: pointer;
}
.frameParent,
.inputoutlinedParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputoutlinedParent {
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameParent {
  align-self: stretch;
  justify-content: center;
  gap: 48px;
}
.frameWrapper {
  border-radius: var(--br-11xl);
  background-color: var(--color-linen);
  width: 858.5px;
  overflow: hidden;
  padding: var(--padding-21xl) 0;
  box-sizing: border-box;
  justify-content: flex-start;
}
.frameWrapper,
.image2Parent,
.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image2Parent {
  justify-content: flex-start;
  gap: 75px;
}
.loginPage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 762px;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  color: var(--color-gray);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 960px) {
  .frameParent,
  .frameWrapper,
  .image2Parent {
    align-self: stretch;
    width: auto;
  }
  .image2Parent {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .loginPage {
    padding-left: var(--padding-32xl);
    padding-right: var(--padding-32xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .invoiceNumberGenerator {
    font-size: 18px;
    text-align: center;
    flex: 1;
  }
  .invoiceNumberGeneratorV101Wrapper {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
}
