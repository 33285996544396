.image2Icon {
  position: relative;
  width: 236px;
  height: 118px;
  object-fit: cover;
}
.welcomeWilson {
  position: relative;
  font-weight: 300;
}
.inputstandard {
  border: 0;
  background-color: transparent;
}
.selectstandardParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.frameParent {
  gap: 89px;
}
.frameParent,
.image2Parent,
.welcomeWilsonParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.welcomeWilsonParent {
  align-self: stretch;
  flex: 1;
  border-radius: 0 var(--br-11xl) var(--br-11xl) 0;
  background-color: var(--color-linen);
  overflow: hidden;
  padding: 32px 35px 16px;
  gap: 28px;
}
.image2Parent {
  width: 290px;
  height: 762px;
  padding: var(--padding-21xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-21xl);
}
.frameChild {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--color-gray);
  box-sizing: border-box;
  height: 1px;
}
.recordsParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px;
}
.frameItem {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: #a6a6a6;
  overflow: hidden;
}
.frameGroup,
.mainPage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  padding: var(--padding-21xl);
  gap: 60px;
  text-align: center;
  font-size: 28px;
}
.mainPage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  overflow: scroll;
  flex-direction: row;
  gap: var(--gap-xl);
  text-align: left;
  font-size: 20px;
  color: var(--color-gray);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 420px) {
  .welcomeWilsonParent {
    align-self: stretch;
    width: auto;
    border-radius: 0;
  }
  .image2Parent {
    width: 100%;
  }
  .frameGroup {
    flex: unset;
    align-self: stretch;
  }
  .mainPage {
    flex-direction: column;
  }
}
