@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* Colors */
  --color-white: #fff;
  --color-gray: #8b755e;
  --color-linen: #f5ebe0;

  /* Gaps */
  --gap-xl: 20px;
  --gap-21xl: 40px;

  /* Paddings */
  --padding-21xl: 40px;
  --padding-32xl: 51px;
  --padding-3xs: 10px;

  /* border radiuses */
  --br-11xl: 30px;
}
